import React, { useEffect, useState }from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './Sections.scss'
import { motion } from 'framer-motion'
import {MdCloseFullscreen} from 'react-icons/md'
import { MotionWrap } from '../../wrapper'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'
import { Modal } from '../../service/sector'
import { getIcon } from '../../service/support'

const mySection = collection(db, "aboutMeDetails")


const Sections = () => {

const [mysection, setMysection] = useState({})
const [clickedImg, setClickedImg] = useState(null)
const params = useParams()

// const nextSlide = () => {
//   setCurrent(current === length - 1 ? 0 : current + 1)
// }

// console.log("NEXTSLIDES:", current)
// const [current, setCurrent] = useState(0);
// const length = item.length;


// if (!Array.isArray(items) || items.length <= 0){
//   return null;
// }
  
  useEffect(() => {
    console.log(mySection)

    const getAboutDetails = async () =>{
      const response = await getDocs(mySection)
    
      const data = response.docs.map((doc) => ({
        ...doc.data(), id:doc.id
      }))
      console.log("MY DATA:", data)
      const filterData = data.filter((element) => {
        console.log("return:",element.aboutMeId === params.id )
        return element.aboutMeId === params.id
      }
      )[0]

      console.log("MY Filter DATA:",filterData)
      console.log("Section response:", data)
      setMysection(filterData)
    }
  
    getAboutDetails()
    return () => {
      
    }
  }, [])
  

  let navigate = useNavigate()
  const handleClose = () => {
    navigate('/')
  }

  const handleClick = (items, index) => {
    setClickedImg(items)
  };

 
const upEffect = {
  whileInView:{x: [-100, 0], opacity: [0, 1]},
  transition:{ duration: 0.5 }
}

  return (
    <>
      <div className="s__main-container">
      <div className="s__box-container">
        <div className="s__main-content">
            <motion.h1
            whileInView={upEffect.whileInView}
            transition={upEffect.transition}
            >
              {mysection?.title}
            </motion.h1>
            <div className="s__head-img">
              <img src={mysection.imgUrl} alt="" />
            <p>{mysection?.description}</p>
          </div>
          <h2>{mysection?.name}</h2>
          <motion.div className="s__section-list">
          {mysection?.platforms?.map(({icon, title}, i) => {
            const {Icon, color} = getIcon(icon);
            return (
              <motion.div
              whileInView={{opacity: [0, 1]}}
              transition={{duration: 0.5}}
              className="s__section-item app__flex"
              key={title}
              >
              <div className="app_flex">
                <div >
                  <Icon className="s__section-icon" color={color}></Icon>
                </div>
              </div>
              <p className="p-text">{title}</p>
            </motion.div>
            );
          })}
        </motion.div>
          <p className="s__name">{mysection?.training}</p>
          <div className="s__image-container">
            {mysection?.images?.map((items, index) => (
              <div key={index} className="s__image-content">
              <img src={items} alt="" onClick={() => handleClick(items, index)}/>
              </div>
              ))}
              {clickedImg && <Modal 
              clickedImg={clickedImg} 
              setClickedImg={setClickedImg}
              />}
          </div>

         
           {/* --webinars--- */}
          <div className="s__image-container">
            {mysection?.webinars?.map((items) => (
              <>
                <div className="s__image-content">
                <img src={items} alt="" onClick={() => handleClick(items)}/>
                </div>
              </>
            ))}
             {clickedImg && <Modal 
              clickedImg={clickedImg} 
              setClickedImg={setClickedImg}
              />}
          </div>

          {/* --futter--- */}
          <div className="s__fimage-container">
            {mysection?.flutter?.map((items) => (
              <>
                <div className="s__fimage-content">
                <img src={items} alt="" onClick={() => handleClick(items)}/>
                </div>
              </>
            ))}
             {clickedImg && <Modal 
              clickedImg={clickedImg} 
              setClickedImg={setClickedImg}
              />}
          </div>
          
            <motion.div
              whileHover={{ scale: [1, 0.9]}}
              whileInView={{ scale: [0, 1]}}
              transition={{ duration: 0.25}}
              className="s__close app__flex"
              onClick={(handleClose)}
              >
              <MdCloseFullscreen/>
            </motion.div>

    
          
        </div>
      </div>
    </div>
    </>
  )
}

export default MotionWrap (Sections, 'sections');
