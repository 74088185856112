import React, { useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'
import { AppWrap, MotionWrap } from '../../wrapper'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'
import './Skills.scss'
import { getIcon } from '../../service/support'

const mySkills = collection(db, "skills")
const myWorks = collection(db, "experience")

const Skills = () => {

  const [experience, setExperience] = useState([])
  const [skills, setSkills] = useState([])

  useEffect(() => {
    console.log(mySkills)
    const getmyExperience = async() => {
      const response = await getDocs(myWorks)

      const data = response.docs.map((doc) => ({
        ...doc.data(), id:doc.id
      }))
      console.log(data)
      console.log("MY EXPERIENCE:", response)
      setExperience(data)

    }

      const getmySkills = async() => {
        const response = await getDocs(mySkills)

        const data = response.docs.map((doc) => ({
          ...doc.data(), id:doc.id
        }))[0]
        console.log(data)
        console.log("MY SKILLS:", data)
        setSkills(data)

      }
      getmySkills ()
      getmyExperience()
    return () => {
      
    }
  }, [])
  console.log("Responseee:", mySkills)

  return (
    <>
      <h2 className="head-txt">Skills and Experience</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills?.language?.map(({icon, title}, i) => {
            const {Icon, color} = getIcon(icon);
            return (
              <motion.div
              whileInView={{opacity: [0, 1]}}
              transition={{duration: 0.5}}
              className="app__skills-item app__flex"
              key={title}
              >
              <div className="app_flex">
                <div >
                  <Icon className="app__skill-icon" color={color}></Icon>
                </div>
              </div>
              <p className="p-text">{title}</p>
            </motion.div>
            );
          })}
        </motion.div>
        <motion.div className="app__skills-exp">
          {experience?.map((myexperience) => (
            <motion.div
              className="app__skills-exp-item"
            >
              <div className="app__skills-exp-year">
                <p className="bold-text s__exp">{myexperience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                    <motion.div
                    whileInView={{opacity: [0, 1]}}
                    transition={{duration: 0.5}}
                    className="app__skills-exp"
                    data-tip
                    data-for={myexperience.name}
                    key={myexperience.name}
                    >
                    <h4 className="bold-text s__exp">{myexperience.name}</h4>
                    <p className="p-text s__p-exp">{myexperience.company}</p>
                    <ReactTooltip
                    id={myexperience.name}
                    effect="solid"
                    arrowColor="#fff"
                    className="skills-tooltip"
                    >
                      {myexperience.description}
                    </ReactTooltip>
                    </motion.div>
              </motion.div>
              {/* <motion.div className="app__skills-exp-works">
                {myexperience?.works?.map((myworks, i) => (
                  <>
                    <motion.div
                    whileInView={{opacity: [0, 1]}}
                    transition={{duration: 0.5}}
                    className="app__skills-exp"
                    data-tip
                    data-for={myexperience.name}
                    key={myexperience.name}
                    >
                    <h4 className="bold-text">{myworks}</h4>
                    <p className="p-text">{myexperience.description}</p>
                    <ReactTooltip
                    id={myexperience.name}
                    effect="solid"
                    arrowColor="#fff"
                    className="skills-tooltip"
                    >
                      {myexperience.description}
                    </ReactTooltip>
                    </motion.div>
                  </>
                ))}
              </motion.div> */}
            </motion.div>
            
          ))}

        </motion.div>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap (Skills, 'app__skills') ,
  'skills',
  "app__bluebg"
  )