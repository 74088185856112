import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Detail.scss";
import { motion } from "framer-motion";
import { MdCloseFullscreen } from "react-icons/md";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { getIcon } from "../../service/support";
import { Modal } from "../../service/sector";
import { settings } from "../../service/sector";
import { AiFillEye, AiFillGithub } from "react-icons/ai";

const myworkDetail = collection(db, "worksDetail");
const Details = () => {

  const [myprojects, setMyprojects] = useState({});
  const [clickedImg, setClickedImg] = useState(null);
  const params = useParams();

  useEffect(() => {
    console.log("WORK DETAILS:", myworkDetail);

    const getWorkDetail = async () => {
      const response = await getDocs(myworkDetail);

      const data = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const filterData = data.filter((element) => {
        console.log("return:", element.workdetailID === params.id);
        return element.workdetailID === params.id;
      })[0];
      setMyprojects(filterData);
      console.log("DETAILS response:", data);
    };
    getWorkDetail();
    return () => {};
  }, []);

  let navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };

  const handleClick = (item, index) => {
    setClickedImg(item);
  };

  const upEffect = {
    whileInView: { x: [-100, 0], opacity: [0, 1] },
    transition: { duration: 0.5 },
  };

  return (
    <div className="details">
      <div className="box__container app__flex">
        <div className="d__main-content">
          <motion.h1
            whileInView={upEffect.whileInView}
            transition={upEffect.transition}
          >
            {myprojects?.title}
          </motion.h1>
          <div className="d__img-container">
            <div className="d__head-img">
              <img src={myprojects.imgHeader} alt={myprojects.title} />
              <div className="d__link-btn">
                <a
                  href={myprojects.projectLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>
                    <AiFillEye />
                  </button>
                </a>
                <a href={myprojects.codeLink} target="_blank" rel="noreferrer">
                  <button>
                    <AiFillGithub />
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="carousel">
            <Slider {...settings}>
              {myprojects?.images?.map((item) => (
                <div className="card">
                  <div className="card-top">
                    <img
                      src={item}
                      alt="imageSlider"
                      onClick={() => handleClick(item)}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            {clickedImg && (
              <Modal clickedImg={clickedImg} setClickedImg={setClickedImg} />
            )}
          </div>

          <p className="d__descriprtion">{myprojects?.description}</p>
          <h2 className="d__name-tech">{myprojects?.name}</h2>
          <motion.div className="d__detail-list">
            {myprojects?.platform?.map(({ icon, title }, i) => {
              const { Icon, color } = getIcon(icon);
              return (
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="d__detail-item app__flex"
                  key={title}
                >
                  <div className="app_flex">
                    <div>
                      <Icon className="d__detail-icon" color={color}></Icon>
                    </div>
                  </div>
                  <p className="p-text">{title}</p>
                </motion.div>
              );
            })}
          </motion.div>
          <motion.div
            whileHover={{ scale: [1, 0.9] }}
            whileInView={{ scale: [0, 1] }}
            transition={{ duration: 0.25 }}
            className="d__close app__flex"
            onClick={handleClose}
          >
            <MdCloseFullscreen />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Details;
