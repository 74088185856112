import React, { useEffect, useState, useRef } from 'react'
import './Footer.scss'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'
import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
import emailjs from '@emailjs/browser';


const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_5kyei78', form.current, 'cskTEpkDFn2X1k2j_')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  return (
    <>
      <h2 className="head-text"> CONNECT WITH ME</h2>      
      <div className="app__footer-cards">
        <div className="app__footer-card">
            <img src={images.mobile} alt="email" />
            <a href="mailto:reneil.moncawe@gmail.com" className="p-text"> reneil.moncawe@gmail.com</a>
        </div>
        <div className="app__footer-card">
            <img src={images.email} alt="email" />
            <a href="tel: +(63) 956159630 " className="p-text"> +(63) 956159630</a>
        </div>
      </div>
      <div className="app__footer-form app__flex">
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <div class="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="name" />
          </div>
          <label>Email</label>
          <div class="app__flex">
            <input className="p-text" type="email" placeholder="Your email" name="email"  />
          </div>
          <label>Subject</label>
          <div class="app__flex">
            <input className="p-text" type="text" placeholder="Subject" name="subject"  />
          </div>
          <label>Message</label>
          <div>
            <textarea
            className="p-text"
            placeholder="Your Message"
            name="message"
            ></textarea>
            </div>
        <button type="submit" className="p-text" value="Send Message"> Send Message</button>
        </form>
</div>
    </>
  )
}

export default AppWrap (
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
) 