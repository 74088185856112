import { language_icon } from "./sector";

export const getIcon = (iconName) => {
    let icon = null;
    icon = language_icon.filter((icon) => icon.ref === iconName);

    let iconObj = {};

    if (icon[0]["color" === undefined]) iconObj = { Icon: icon[0].value};
    else iconObj = {Icon: icon[0].value, color: icon[0].color};

    console.log("GET ICON", iconObj)
    return icon.length > 0 ? iconObj : null;
  }