import { 
    SiIonic, 
    SiCss3, 
    SiFramer, 
    SiAdobexd, 
    SiHtml5, 
    SiFigma, 
    SiSass, 
    SiAngular, 
    SiReact, 
    SiFlutter,
    SiAdobecreativecloud,
    SiFirebase,
    SiCanva,
    SiAdobephotoshop,
    SiNodedotjs,
    SiPostgresql,
    SiLeaflet,
    SiJavascript,
    SiNpm,
    SiYarn,
    SiDart,
} from 'react-icons/si'

// import {
//   FaArrowAltCircleRight, FaArrowAltCircleLeft
// } from 'react-icons/fa'

export const language_icon = [
    {
      value: SiHtml5,
      ref: "html5",
      color: "#E34F26",
    },
    {
      value: SiCss3,
      ref: "css3",
      color: "#1572B6",
    },
    {
      value: SiIonic,
      ref: "ionic",
      color: "#3880FF",
    },
    {
      value: SiFramer,
      ref: "framer",
      color: "#0055FF",
    },
    {
      value: SiAdobexd,
      ref: "adobeXD",
      color: "#FF61F6",
    },
    {
      value: SiFigma,
      ref: "figma",
      color: "#F24E1E",
    },
    {
      value: SiSass,
      ref: "scss",
      color: "#CC6699",
    },
    {
      value: SiAngular,
      ref: "angular",
      color: "#DD0031",
    },
    {
      value: SiReact,
      ref: "react",
      color: "#61DAFB",
    },
     {
      value: SiFlutter,
      ref: "flutter",
      color: "#02569B",
    },
    {
      value: SiAdobecreativecloud,
      ref: "adobe",
      color: "#DA1F26",
    },
    {
      value: SiFirebase,
      ref: "firebase",
      color: "#FFCA28",
    },
    {
      value: SiCanva,
      ref: "canva",
      color: "#00C4CC",
    },
    {
      value: SiAdobephotoshop,
      ref: "adobephoto",
      color: "#31A8FF",
    },
    {
      value: SiNodedotjs,
      ref: "nodejs",
      color: "#339933",
    },
    {
      value: SiJavascript,
      ref: "javascript",
      color: "#F7DF1E",
    },
    {
      value: SiPostgresql,
      ref: "postgres",
      color: "#4169E1",
    },
    {
      value: SiLeaflet,
      ref: "lefleat",
      color: "#199900",
    },
      {
      value: SiNpm,
      ref: "npm",
      color: "#CB3837",
    },
    {
      value: SiYarn,
      ref: "yarn",
      color: "#2C8EBB",
    },
    {
      value: SiDart,
      ref: "dart",
      color: "#0175C2",
    },
  ];

  export const Modal =({clickedImg, setClickedImg}) => {

    const handleClick = (e) => {
      if(e.target.classList.contains("dismiss")){
        setClickedImg(null)
      }
    }
    return (
      <>
        <div className="overlay dismiss" onClick={handleClick}>
          <img src={clickedImg} alt="bigger pic" />
          <span className="dismiss" onClick={handleClick}>
            X
          </span>
          {/* <FaArrowAltCircleLeft className="overlay-arrows_left" />
          <FaArrowAltCircleRight className="overlay-arrows_right"/> */}
        </div>
      </>
    );
  }

export  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  