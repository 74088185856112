import React, { useState, useEffect} from 'react'
import { AiFillEye, AiFillGithub, AiOutlineFullscreen} from 'react-icons/ai'
import { motion } from 'framer-motion'
import { AppWrap } from '../../wrapper'
import './Work.scss'
import { Link} from 'react-router-dom'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'


const worksProject = collection(db, "worksPoject")

const Work = () => {
  const [activeFilter, setActiveFilter] = useState('All')
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1})
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([])

  useEffect(() => {
    console.log(worksProject)

      const getWorksProject = async() => {
        const response = await getDocs(worksProject)

        const data = response.docs.map((doc) => ({
          ...doc.data(), id:doc.id
        }))
        console.log(data)
        console.log("Responseee:", response)
        setWorks(data)
        setFilterWork(data)

      }
      getWorksProject()
    return () => {
      
    }
  }, [])
  console.log("Responseee:", worksProject)

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{y:100, opacity: 0}]);

    setTimeout(() => {
      setAnimateCard([{ y:0, opacity: 1 }]);

      if(item === 'All') {
        setFilterWork(works);
      } else{
        setFilterWork(works.filter((work) => work.tags.includes(item)))
      }
    }, 500);
  }

  const upFilter = {
    whileInView:{y: [100, 0], opacity: [0, 1]},
    transition:{ duration: 0.5 }
  }
  return (
    <>
      <motion.div
        whileInView={upFilter.whileInView}
        transition={upFilter.transition}
        className="head-content"
      >
        <motion.h5
          whileInView={{y: [80, 0], opacity: [0, 1]}}
          transition={{ duration: 0.5 }}
        >My creative</motion.h5>
        <motion.h2 className="head-txt"
          whileInView={{ y: [80, 0], opacity: [0, 1]}}
          transition={{ duration: 0.8, delayChildren: 0.8 }}
        >Portfolio Section</motion.h2>
      </motion.div>

      <div className="app__work-filter">
        {['UI/UX', 'Web App', 'Mobile App', 'React JS', 'All'].map((item, index) => (
          <>
         
          <div
          key={index}
          onClick={() => handleWorkFilter(item)}
          className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
          </>
        ))}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
        >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img src={work.imgUrl} alt={work.name} />

              <motion.div
                whileHover={{opacity: [0, 1]}}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5}}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                  whileHover={{ scale: [1, 0.9]}}
                  whileInView={{ scale: [0, 1]}}
                  transition={{ duration: 0.25}}
                  className="app__flex"
                  >
                    <AiFillEye/>
                  </motion.div>
                </a>

                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                  whileHover={{ scale: [1, 0.9]}}
                  whileInView={{ scale: [0, 1]}}
                  transition={{ duration: 0.25}}
                  className="app__flex"
                  >
                    <AiFillGithub/>
                  </motion.div>
                </a>

                <Link to={`/details/${work.id}`}>
                  <motion.div
                  whileHover={{ scale: [1, 0.9]}}
                  whileInView={{ scale: [0, 1]}}
                  transition={{ duration: 0.25}}
                  className="app__flex"
                  >
                    <AiOutlineFullscreen/>
                  </motion.div>
                </Link>

              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="Bold-text">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p>

                <div className="app__work-tag app__flex">
                  <p className="p-text">{work.tags}</p>
                </div>
              <Link 
              to={`/details/${work.id}`}
              className="w__link"
              >
                <button className="mobile_btn">View More</button>
              </Link>
            </div>
          
          </div>
        ))}

      </motion.div>
    </>
  )
}

export default AppWrap (Work, 'work');
  