import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import './About.scss'
import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'
import { async } from '@firebase/util'
import { Link } from 'react-router-dom'

const aboutMe = collection(db, "aboutMe");


const scaleEffect = {
  whileInView: {
    x: [-100, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.7
    }
  }
}

const About = () => {

  //array distructuring ni useState nagrereturn nya yung value
  //setAboutme is function babagogin nya yung value nung aboutme
  const [aboutme, setAboutme] = useState([]) 

  //useEffect once nagload ung page
  // example: console.log("response: ",response)
  // old function: function getAboutMe(){} 
  // new function: const getAboutMe = () => {}
  // paramter yun yung laman ng function 
  /* example parameter : const printName = (name, lname, email) =>{
      console.log("response: ", name)
  }
  */
  useEffect(() => {
    console.log(aboutMe)

    const getAboutMe = async () => {
      const response = await getDocs(aboutMe)
      
      const data = response.docs.map((doc) => ({
        ...doc.data(), id:doc.id
      }))
      console.log(data)
      console.log("response: ",response)
      setAboutme(data)
    }
    getAboutMe()
    return () => {
      
    }
  }, [])
  console.log("Response", aboutMe)
  
  const upFilter = {
    whileInView:{y: [80, 0], opacity: [0, 1]},
    transition:{ duration: 0.5 }
  }

  return (
    <>
    <motion.h2 className="a__head-text"
      whileInView={upFilter.whileInView}
      transition={upFilter.transition}
    > "Become addicted to constant and never ending self improvement"</motion.h2>
    <motion.span className="a__author-txt"
    whileInView={{ y: [80, 0], opacity: [0, 1]}}
    transition={{ duration: 0.8, delayChildren: 0.8 }}
    >-Anthony J. D'Angelo-</motion.span>

    <div className="a__box-container">
         <div 
         className="a__main-container">
           <div className="a__container">
            <div className="a__background">
             <img src={images.my_profiles} alt="my_image" />
             </div>
           </div>
        <div className="a__context">
        <h1>About Me</h1>
         <p>
         Reneil V. Moncawe was born in San Agustin Ibaan Batangas. He graduated a degree in Information Technology with a specialization in Network Technology at the Batangas State University Alangilan campus.
         He also works as a graphic designer and web developer at Erovoutika Electronics Robotics Automation as part of their On the Job Training.
         During his college and training, he discovered his passion for UI/UX, Web design and graphic designs. He strives to be competent in this field, 
         and as graduated an IT , he aims to deliver a website that meets the needs of the user.
         </p>
         </div>
       </div>
    </div>
   
    <div className="app__profiles">
      {aboutme.map((abouts) => (
        <>
        <motion.div
          whileInView={{opacity: 1}}
          whileHover={{ scale: 1.1}}
          transition={{ duration: 0.5, type: 'tween'}}
          className="app__profile-item"
          key={abouts.title}
        >
          <img src={abouts.imgUrl} alt={abouts.title} />
          <h2 className="bold-text txt-title" style={{marginTop: 20}}>{abouts.title}</h2>
          <p className="p-text txt-description" style={{marginTop: 10}}>{abouts.description}</p>
          <Link to={`sections/${abouts.id}`}>
          <button>
            <div>
              <span></span>
                <p>View More</p>
            </div>
          </button>
          </Link>
        </motion.div>
        </>
      ))}
    </div>
    </>
  )
}

export default AppWrap (
  MotionWrap(About, 'app__about'),
  'about',
  "app__bluebg"
  )