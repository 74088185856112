import React from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar } from '../../components'
import About from '../About/About'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Skills from '../Skills/Skills'
import Work from '../Work/Work'


const MainApp = () => {
  return (
    <div className='app'>
    <Navbar/>
    <Header/>
    <About/>
    <Work/>
    <Skills/> 
    <Footer/>
    <Outlet/>
  </div>
  )
}

export default MainApp