import React from 'react'
import {motion} from 'framer-motion'
import { AppWrap } from '../../wrapper'
import { images } from '../../constants'
import CV from '../../assets/CV.pdf'
import './Header.scss'


const scaleVariants ={
  whileInView:{
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }}
}
const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{x: [-100, 0], opacity: [0, 1]}}
        transition={{ duration: 0.5 }}
        className="app__header-info"
        >
        <div className="app__header-badge">
          <div className="h__me">
            <div>
              <p className="h__hello">Meet</p>
              <h1 className="h__name">Reneil</h1>
            </div>
          </div>

          <div className="h__dev">
            <p className="p-text">Front-end Developer/Web Designer</p>
            <p className="p-text">UI/UX </p>
          </div>
          <button className="h__button">
            <div>
              <span></span>
              <a className="p=text" href={CV} download> Download CV</a>
            </div>
          </button>
        </div>

      </motion.div>
      
      <motion.div
       whileInView={{ opacity: [0, 1]}}
       transition={{ duration: 0.5, delayChildren: 0.5 }}
       className="app__header-img"
        >
          <img src={images.profile2} alt="profile_bg" />
          <motion.img
            whileInView={{ scale: [0, 1]}}
            transition={{ duration: 1, ease: 'easeInOut' }}
            src={images.circle}
            alt="profile_circle"
            className="overlay_circle"

          />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={ scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.html, images.css, images.react].map((circle, index) => (
          <div className="circle_cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home')