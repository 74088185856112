import React from 'react'
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.scss'
import Details from './container/Detail/Details'
import MainApp from './container/main-app/MainApp'
import Sections from './container/Sections/Sections'


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<MainApp/>}
          >
          <Route 
          path='details/:id'
          element={<Details/>}
          />
          <Route 
          path='sections/:id'
          element={<Sections/>}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App