import React from "react";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a href="https://www.linkedin.com/in/reneil-moncawe-985914249/"  target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://github.com/Moncss"  target="_blank" rel="noreferrer">
        <BsGithub />
      </a>
      <a href="" target="_blank" rel="noreferrer">
        <FaFacebookF />
      </a>
    </div>
  );
};

export default SocialMedia;
